import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-8" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      class: "embed-responsive-item rounded h-500px w-100",
      src: _ctx.iframeUrl,
      allowfullscreen: "",
      style: {"width":"100%","height":"500px"}
    }, null, 8, _hoisted_2)
  ]))
}